import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import AffineTransformationQueryRepositoryInterface from '@/services/MapEditor/domain/repository/AffineTransformationQueryRepositoryInterface'
import store from '@/store'
import { GetAffineTransformationByGuid } from '@/services/MapEditor/infrastructure/store/modules/AffineTransformation/action-types'

export default class AffineTransformationQueryRepository extends AbstractQueryRepository implements AffineTransformationQueryRepositoryInterface
{
    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetAffineTransformationByGuid(guid));
    }
}